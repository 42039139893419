<template>
  <div class="Page">
    <div class="opt-bar flex">
      <el-button @click="handleToday" size="small" v-ripple>今天</el-button>
      <el-input size="small"
          @keyup.enter.native="onCurrentPageChange(1)"
          class="searcher-input"
          placeholder="关键词"
          clearable
          @clear="onSkeyClear"
          v-model="skey">
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      <el-button @click="onCurrentPageChange(1)" type="primary" size="small"><i class="iconfont">&#xe622;</i></el-button>
      <el-select size="small" v-model="role" @change="onCurrentPageChange(1)"
        class="roleSelecter"  popper-class="el-select-pannel" clearable
        placeholder="角色">
        <el-option
          v-for="item in roleList"
          :key="item"
          :label="item"
          :value="item">
        </el-option>
      </el-select>
      <el-select size="small" v-model="stat" @change="onCurrentPageChange(1)"
        class="statSelecter"  popper-class="el-select-pannel" clearable
        placeholder="状态">
        <el-option
          v-for="item in statList"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
    <el-table :data="page.list" element-loading-text="Loading..."
      size="small"
      border fit highlight-current-row
      header-row-class-name="tableHeader"
      :height="tableHeight"
      :row-class-name="tableRowClass"
      row-key="id"
      default-expand-all
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
    >
      <el-table-column fixed align="center" label="Id" width="95">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="名称" width="100" align="left">
        <template slot-scope="scope">
          <span v-if="scope.row.upid > 0">&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <i :class="'iconfont '+scope.row.icon"></i>
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column label="username" width="120" align="left">
        <template slot-scope="scope">
          {{ scope.row.username}}
        </template>
      </el-table-column>
      <el-table-column label="stat" width="60" align="center">
        <template slot-scope="scope">
          {{ scope.row.stat}}
        </template>
      </el-table-column>
      <el-table-column label="code" width="60" align="center">
        <template slot-scope="scope">
          {{ scope.row.code}}
        </template>
      </el-table-column>
      <el-table-column label="clz" width="100" align="left">
        <template slot-scope="scope">
          {{ scope.row.clz}}
        </template>
      </el-table-column>
      <el-table-column label="mth" width="160" align="left">
        <template slot-scope="scope">
          <span>
            {{ scope.row.mth}}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="pms" width="230" align="left">
        <template slot-scope="scope">
          <div class="long-text">{{ scope.row.pms}}</div>
        </template>
      </el-table-column>
      <el-table-column label="result" align="left">
        <template slot-scope="scope">
          <div class="long-text">{{ scope.row.result}}</div>
        </template>
      </el-table-column>
      <el-table-column label="atime" width="160" align="left" class="hidden-md-and-down">
        <template slot-scope="scope">
          {{util.formatDate(scope.row.atime)}}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="70" align="center" fixed="right">
        <template slot-scope="scope">
          <icon-btn @click="handleTraceLog(scope.row)" tip="跟踪"
            icon="iconxtracelianluzhuizong"
            type="warning"
            ></icon-btn>
        </template>
      </el-table-column>
    </el-table>
    <div class="pager">
      <div class="pager-info">共 {{page.total}} 条 第 {{page.currentPage}}/{{page.pageCount}} 页</div>
      <el-pagination
        @size-change="onPageSizeChange"
        @current-change="onCurrentPageChange"
        :current-page="page.currentPage"
        :page-sizes="[20, 30, 40, 50,100]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total">
      </el-pagination>
      <div class="pager-time" v-if="queryTime > 0">查询{{queryTime}}毫秒</div>
    </div>
    <div v-if="refresh"></div>
  </div>
</template>

<script>
import IconBtn from '../../components/btns/IconBtn.vue';
export default {
  components: {
    IconBtn
  },
  data() {
    return {
       refresh: false,
      skey: '',
      page: {pg: 1, list:[], total: 0, pageSize: 20},
      role: '',
      roleList: ['ADMIN', 'MGR', 'USER'],
      stat: '',
      statList: [
        {value: 1, label: '启用'},
        {value: 0, label: '禁用'},
      ],
      tableHeight: 560,
      queryTime: '',
    };
  },
  created(opt) {
    this.resize();
    window.onresize = this.resize();
    this.queryRoles()
    this.onCurrentPageChange(1);
  },
  methods: {
    resize() {
      var clientHeight = document.documentElement.clientHeight
      this.tableHeight = clientHeight - 160
    },
    handleToday() {

    },
    onSkeyClear() {
      this.onCurrentPageChange(1)
    },
    async onCurrentPageChange(pg){
      var pm = {
        pg : pg,
        pageSize: this.page.pageSize,
        skey: this.skey,
        role: this.role,
        stat: this.stat
      }
      this.post('api/sys/logs', pm, res=>{
        if (res.code != 0) {
          this.$message.error('出错了~' + res.msg);
          return
        }
        this.queryTime = res.pageTime
        this.page = res.data
      })
    },
    onPageSizeChange(e) {
      this.page.pageSize = e;
      this.onCurrentPageChange(1)
    },
    queryRoles() {
      this.post('api/sys/roles', {}, res=>{
        if (res.code != 0) {
          this.$message.error('获取角色信息失败：' + res.msg)
          return
        }
        var list = res.data
        this.roleList = []
        list.forEach(element => {
          this.roleList.push(element.id)
        });
      })
    },
    transStat(stat) {
      var res = ''
      switch(stat) {
        case 0:
          res = '错误'
          break;
        case 1:
          res = '正常'
          break;
      }
      return res
    },
    tableRowClass({row, rowIndex}) {
      if (row.upid == 0) {
        return 'success-row'
      }
      if (row.stat === 0) {
        return "error-row"
      } else {
        if (rowIndex % 2 === 0) {
          return '';
        } else {
          return 'second-row'
        }
      }
    },
  } //methods
};
</script>

<style scoped lang="scss">
.Page {
  margin-top: 0px;

  .roleSelecter {
    width: 8em;
  }

  .statSelecter{
    width: 7em;
  }
}

.long-text {
  width: 100%;
  text-overflow:ellipsis;
  white-space: nowrap;
}
</style>
